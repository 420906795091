import * as SliderPrimitive from "@radix-ui/react-slider";
import React from "react";
import { cn } from "~/util/css";

export const RangeSlider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <SliderPrimitive.Root
      ref={ref}
      className={cn(
        "relative flex w-full touch-none select-none items-center",
        props.orientation === "vertical" ? "flex-col h-16 w-4" : "w-full",
        className,
      )}
      {...props}
    >
      <SliderPrimitive.Track
        className={cn(
          "relative h-[5px] grow overflow-hidden rounded-full bg-white ",
          props.orientation === "vertical" ? "w-[5px]" : "w-full",
        )}
      >
        <SliderPrimitive.Range
          className={cn(
            "absolute h-full bg-primary",
            props.orientation === "vertical" ? "w-full" : "w-auto",
          )}
        />
      </SliderPrimitive.Track>
      <SliderPrimitive.Thumb className="block h-4 w-4 rounded-full border border-primary bg-primary shadow transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50" />
    </SliderPrimitive.Root>
  );
});
RangeSlider.displayName = SliderPrimitive.Root.displayName;
