import { Loader2 } from "lucide-react";
import React from "react";
import { useInView } from "react-intersection-observer";
import ReactPlayer from "react-player";
import type ReactPlayerType from "react-player";
import type { OnProgressProps } from "react-player/base";
import { ClientOnly } from "remix-utils/client-only";
import { VideoControls } from "./video-controls";

interface VideoPlayerProps {
  url: string;
  onClick?: () => void;
  showControls?: boolean;
  loop?: boolean;
  shouldPlayInView?: boolean;
}

export function VideoPlayer(props: VideoPlayerProps) {
  const videoRef = React.useRef<ReactPlayerType | null>(null);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [isMuted, setIsMuted] = React.useState(true);
  const [played, setPlayed] = React.useState(0);
  const [volume, setVolume] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(
    props.shouldPlayInView ?? true,
  );
  const [loop, setLoop] = React.useState(props.loop || false);
  const { shouldPlayInView = true } = props;

  const { ref, inView } = useInView({
    threshold: 0.9,
  });

  const handlePause = () => {
    setIsPlaying(false);
  };

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handleMute = () => {
    setIsMuted(true);
    setVolume(0);
  };

  const handleUnMute = () => {
    setIsMuted(false);
    setVolume(volume === 0 ? 0.5 : 0);
  };

  const handleOnProgress = (state: OnProgressProps) => {
    setPlayed(state.played);
  };

  const handleSeek = (value: number) => {
    if (!videoRef.current) {
      return;
    }
    setPlayed(value / 100);
    videoRef.current.seekTo(value / 100);
  };

  const handleChangeVolume = (value: number) => {
    const newVolume = value / 100;
    setVolume(newVolume);
    setIsMuted(newVolume === 0);
  };

  const handleOnEnded = () => {
    if (loop) {
      setIsPlaying(true); // Play again if loop is enabled
    } else {
      setIsPlaying(false);
    }
  };

  React.useEffect(() => {
    if (!shouldPlayInView) {
      return;
    }
    // auto play/pause video
    if (inView) {
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
    }
  }, [inView, shouldPlayInView]);

  const twitchRegex = /twitch\.tv/;
  const isTwitchVideo = twitchRegex.test(props.url);

  return (
    <div
      className="relative group w-full h-full bg-black flex items-center justify-center"
      ref={ref}
    >
      <ClientOnly>
        {() => (
          <ReactPlayer
            className="absolute top-0 left-0 w-full"
            playsinline
            ref={videoRef}
            url={props.url}
            muted={isMuted}
            playing={isPlaying}
            onProgress={handleOnProgress}
            volume={volume}
            onEnded={handleOnEnded}
            width="100%"
            height="100%"
            onBufferEnd={() => setIsLoading(false)}
            onClick={props?.onClick}
            loop={loop}
          />
        )}
      </ClientOnly>

      {!isTwitchVideo && isLoading ? (
        <div className="flex z-10">
          <Loader2 size={24} className="animate-spin" color="#ffffff" />
          <p className="text-base text-white">Loading...</p>
        </div>
      ) : props.showControls ? (
        <VideoControls
          isMuted={isMuted}
          isPlaying={isPlaying}
          played={played}
          volume={volume}
          onPlay={handlePlay}
          onPause={handlePause}
          onChangeVolume={handleChangeVolume}
          onMute={handleMute}
          onUnMute={handleUnMute}
          onSeek={handleSeek}
        />
      ) : null}
    </div>
  );
}
