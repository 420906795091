import { Pause, Play, Volume2, VolumeX } from "lucide-react";
import { RangeSlider } from "../range-slider";

interface VideoControlsProps {
  isPlaying: boolean;
  isMuted: boolean;
  volume: number;
  played: number;
  onPause: () => void;
  onPlay: () => void;
  onUnMute: () => void;
  onMute: () => void;
  onSeek: (value: number) => void;
  onChangeVolume: (value: number) => void;
}

export function VideoControls({
  isPlaying,
  isMuted,
  volume,
  played,
  onPause,
  onPlay,
  onMute,
  onUnMute,
  onChangeVolume,
  onSeek,
}: VideoControlsProps) {
  return (
    <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black hidden group-hover:block z-[48]">
      <div className="text-white flex items-center px-4">
        {/* Play / pause button*/}
        {isPlaying ? (
          <button
            type="button"
            onClick={onPause}
            className="mr-4 hover:opacity-25 p-2"
          >
            <Pause />
          </button>
        ) : (
          <button
            type="button"
            onClick={onPlay}
            className="mr-4 hover:opacity-25 p-2"
          >
            <Play />
          </button>
        )}
        {/* volume button / slider */}
        <div className="relative flex items-center group/volume">
          {isMuted ? (
            <button
              type="button"
              onClick={onUnMute}
              className="hover:opacity-25 p-2"
            >
              <VolumeX />
            </button>
          ) : (
            <button
              type="button"
              onClick={onMute}
              className="hover:opacity-25 p-2"
            >
              <Volume2 />
            </button>
          )}
          <div className="absolute bottom-[40px] left-0 bg-black bg-opacity-70 px-2 py-4 rounded-full hidden group-hover/volume:flex items-center justify-center ">
            <RangeSlider
              className="h-28"
              min={0}
              max={100}
              value={[Math.round(volume * 100)]}
              onValueChange={([value]) => onChangeVolume(value)}
              orientation="vertical"
            />
          </div>
        </div>
      </div>
      {/* Seek handler */}
      <div className="py-3 px-2">
        <RangeSlider
          min={0}
          max={100}
          value={[Math.round(played * 100)]}
          step={1}
          onValueChange={([value]) => {
            onSeek(value);
          }}
        />
      </div>
    </div>
  );
}
